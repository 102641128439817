<script>
import { get } from 'vuex-pathify'
import baseComp from '#/v-shop/vue-src/v-shop/account/comp_UserpanelLayout.vue'

export default {
	extends: baseComp,
	computed: {
		user: get('shop/user'),
		bioData() {
			return this.user.bioData
		},
	},
	created() {
		if (!this.bioData) return

		this.items.splice(0, 0, {
			title: 'Mi Perfil Bioesencia',
			icon: 'mdi-star',
			attrs: { to: { name: 'user.bio-profile' } },
		})
		this.items.splice(3, 0, {
			title: 'Mis Balances',
			icon: 'mdi-chart-box',
			attrs: { to: { name: 'user.bio-balances' } },
		})
	},
}
</script>

