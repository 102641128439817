<script>
import { get } from 'vuex-pathify'
export default {
	data() {
		return {
			uploading: false,
		}
	},
	computed: {
		user: get('shop/user'),
		bioData() {
			return this.user.bioData
		},
		bioImage() {
			return this.user.bioImage
		},
		nameInitials() {
			return this.bioData.Alias.split(' ')
				.slice(0, 2)
				.map((n) => n[0])
				.join('')
				.toUpperCase()
		},
	},
	methods: {
		async uploadImage(image) {
			await this.$shopApi.post({
				url: '/users-bio-image',
				loading: (v) => (this.uploading = v),
				files: { image },
				data: {},
				onSuccess: ({ data }) => {
					this.$store.set('shop/user', { ...this.user, bioImage: data.bioImage })
				},
				axios: { headers: { 'x-custom-post': 'true' } },
			})
		},
		onInputChange(e) {
			console.log(e.target.files)
			if (!e.target.files?.[0]) return
			this.uploadImage(e.target.files[0]).then(() => {
				e.target.value = ''
			})
		},
	},
	mounted() {
		if (!this.bioData) {
			window.location.href = this.$router.resolve({ name: 'user.account' }).href
		}
	},
}
</script>

<template>
	<UserpanelLayout>
		<v-card v-if="bioData">
			<div class="hero d-flex align-items-start">
				<div class="hero__img-cont">
					<v-hover v-slot="{ hover }">
						<div
							class="hero__img-input"
							:style="{
								backgroundImage: bioImage ? `url('/bio-images/${bioImage}')` : null,
								backgroundColor: bioImage ? null : 'rgba(17, 7, 148)',
							}"
							@click="$refs.input.click()"
						>
							<input type="file" @change="onInputChange" class="d-none" ref="input" />
							<div class="hero__img-initials" v-if="!bioImage">{{ nameInitials }}</div>
							<div class="hero__img-overlay" :style="{ opacity: hover || uploading ? 1 : 0 }">
								<v-progress-circular v-if="uploading" indeterminate color="white" />
								<v-icon v-else color="white">mdi-camera</v-icon>
							</div>
						</div>
					</v-hover>
				</div>
				<div class="hero__text-cont">
					<div class="hero__text-welcome">Bienvenid@,</div>
					<h1 style="line-height: 1">{{ bioData.Alias }}</h1>
					<div class="hero__text-lista">{{ bioData.Lista_de_precio_categoria_nombre }}</div>
				</div>
			</div>
			<div class="pa-6">
				<v-row>
					<v-col cols="12" md="4">
						<div class="datakey">Asesor Comercial</div>
						<div class="dataval">(Coordinador)</div>
					</v-col>
					<v-col cols="12" md="4">
						<div class="datakey">Ejecutiva de Cuentas</div>
						<div class="dataval">Florencia Castro</div>
					</v-col>
					<v-col cols="12">
						<v-divider></v-divider>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<v-chip color="rgba(17, 7, 148)" class="white--text" pill>Contacto</v-chip>
					</v-col>
					<v-col cols="12" md="4">
						<div class="datakey">Nombre / Apellido</div>
						<div class="dataval">{{ bioData.Alias }}</div>
					</v-col>
					<v-col cols="12" md="4">
						<div class="datakey">Email de Contacto</div>
						<div class="dataval">{{ bioData.Mail }}</div>
					</v-col>
					<v-col cols="12" md="4">
						<div class="datakey">Celular</div>
						<div class="dataval">{{ bioData.Celular }}</div>
					</v-col>
					<v-col cols="12">
						<v-divider></v-divider>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<v-chip color="rgba(17, 7, 148)" class="white--text" pill>Domicilio</v-chip>
					</v-col>
					<v-col cols="12" md="4">
						<div class="datakey">Dirección</div>
						<div class="dataval">{{ bioData.Direccion.Direccion }}</div>
					</v-col>
					<v-col cols="12" md="8">
						<div class="datakey">Localidad / Provincia</div>
						<div class="dataval">
							{{ bioData.Direccion.Localidad }}, {{ bioData.Direccion.Provincia }} (CP
							{{ bioData.Direccion.Cp }})
						</div>
					</v-col>

					<v-col cols="12">
						<v-divider></v-divider>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<v-chip color="rgba(17, 7, 148)" class="white--text" pill>Facturación</v-chip>
					</v-col>
					<v-col cols="12" md="4">
						<div class="datakey">Razón Social</div>
						<div class="dataval">{{ bioData.RazonSocial }}</div>
					</v-col>
					<v-col cols="12" md="4">
						<div class="datakey">CUIT</div>
						<div class="dataval">{{ bioData.cuit }}</div>
					</v-col>
					<v-col cols="12" md="4">
						<div class="datakey">Condición IVA</div>
						<div class="dataval">{{ bioData.iva }}</div>
					</v-col>
				</v-row>
			</div>
			<div class="grey lighten-2 py-3 px-6">
				* Cualquier modificación de datos comunicate con tu ejecutiva de cuentas para actualizarlo.
			</div>
		</v-card>
	</UserpanelLayout>
</template>

<style lang="scss" scoped>
.hero {
	padding: 36px 24px;
	background-color: #f891a5;
	&__img-cont {
		padding: 0 24px;
	}

	&__img-input {
		width: 165px;
		height: 165px;
		border-radius: 50%;
		background-color: #fff;
		background-size: cover;
		background-position: center;
		position: relative;
		overflow: hidden;
		border: 1px solid rgba(0, 0, 0, 0.1);
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		transition: 0.3s;
		&:hover {
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
		}
	}

	&__img-initials {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 4rem;
		font-weight: 800;
		color: #fff;
	}
	&__img-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	&__text-cont {
		width: 100%;
		color: rgba(17, 7, 148);
		position: relative;
		top: 12px;
	}
	&__text-welcome {
		font-size: 2rem;
		font-weight: 300;
		padding-bottom: 20px;
	}
	&__text-cont h1 {
		font-weight: 800;
		font-size: 3.5rem;
		padding-bottom: 48px;
	}
	&__text-lista {
		font-weight: 200;
		font-size: 1.6rem;
	}
}

.v-card {
	.datakey {
		font-weight: 800;
		padding-bottom: 6px;
		font-size: 1.2rem;
	}
	.dataval {
		font-weight: 400;
	}
}
</style>

