<script>
import { get } from 'vuex-pathify'
const PER_PAGE = 10
export default {
	data() {
		return {
			balances: null,
			loading: true,
			filterType: 'thisMonth',
			monthFrom: null,
			monthTo: null,
			productsPage: 1,
			ordersPage: 1,
			categoriesPage: 1,
		}
	},
	computed: {
		user: get('shop/user'),
		bioData() {
			return this.user.bioData
		},
		noResults() {
			return !this.loading && this.balances && !this.balances.monthlyTotals.length
		},
		productsTotalsInPage() {
			return this.balances.productsTotals.slice(
				(this.productsPage - 1) * PER_PAGE,
				this.productsPage * PER_PAGE
			)
		},
		productsTotalsLastPage() {
			return Math.ceil(this.balances.productsTotals.length / PER_PAGE)
		},
		ordersTotalsInPage() {
			return this.balances.ordersTotals.slice((this.ordersPage - 1) * PER_PAGE, this.ordersPage * PER_PAGE)
		},
		ordersTotalsLastPage() {
			return Math.ceil(this.balances.ordersTotals.length / PER_PAGE)
		},
		categoriesTotalsInPage() {
			return this.balances.categoriesTotals.slice(
				(this.categoriesPage - 1) * PER_PAGE,
				this.categoriesPage * PER_PAGE
			)
		},
		categoriesTotalsLastPage() {
			return Math.ceil(this.balances.categoriesTotals.length / PER_PAGE)
		},
	},
	watch: {
		filterType() {
			this.monthFrom = null
			this.monthTo = null
			this.$nextTick(() => {
				this.loadBalances()
			})
		},
		monthFrom() {
			this.$nextTick(() => {
				this.loadBalances()
			})
		},
		monthTo() {
			this.$nextTick(() => {
				this.loadBalances()
			})
		},
		productsPage(value, oldValue) {
			if (!oldValue || this.loading) return
			this.scrollTo('#products-totals')
		},
		ordersPage(value, oldValue) {
			if (!oldValue || this.loading) return
			this.scrollTo('#orders-totals')
		},
		categoriesPage(value, oldValue) {
			if (!oldValue || this.loading) return
			this.scrollTo('#categories-totals')
		},
	},
	methods: {
		scrollTo(selector) {
			this.$nextTick(() => {
				this.$vuetify.goTo(selector, {
					duration: 200,
					offset: document.querySelector('.appbar').clientHeight + 24,
				})
			})
		},
		loadBalances() {
			if (this.filterType == 'chooseMonth' && !this.monthFrom) {
				return
			}
			if (
				this.filterType == 'rangeMonths' &&
				(!this.monthFrom || !this.monthTo || this.monthFrom > this.monthTo)
			) {
				return
			}

			this.$shopApi.post({
				url: '/users-bio-balances',
				loading: (v) => (this.loading = v),
				data: {
					filterType: this.filterType,
					monthFrom: this.monthFrom,
					monthTo: this.monthTo,
				},
				onSuccess: ({ data }) => {
					this.productsPage = 1
					this.ordersPage = 1
					this.categoriesPage = 1
					this.balances = data
				},
			})
		},
	},
	mounted() {
		if (!this.bioData) {
			window.location.href = this.$router.resolve({ name: 'user.account' }).href
			return
		}
		this.loadBalances()
	},
}
</script>

<template>
	<UserpanelLayout>
		<!-- filters -->
		<div class="py-3">
			<v-row>
				<v-col cols="12">
					<v-card>
						<v-card-title>
							<v-icon class="mr-2">mdi-filter</v-icon>
							Filtrar
						</v-card-title>
						<v-card-text>
							<v-row>
								<v-col cols="12" md="4">
									<v-select
										v-model="filterType"
										:items="[
											{ text: 'Este mes', value: 'thisMonth' },
											{ text: 'Mes anterior', value: 'lastMonth' },
											{ text: 'Elige un Mes', value: 'chooseMonth' },
											{ text: 'Rango de Meses', value: 'rangeMonths' },
										]"
										label=""
										outlined
										dense
										hide-details
										:disabled="loading"
									></v-select>
								</v-col>
								<v-col
									cols="12"
									md="3"
									v-if="filterType == 'chooseMonth' || filterType == 'rangeMonths'"
								>
									<MonthPickerDialog
										:label="filterType == 'chooseMonth' ? 'Mes' : 'Desde'"
										v-model="monthFrom"
										date-text-format="MM/YY"
										:textfieldAttrs="{
											outlined: true,
											dense: true,
											hideDetails: true,
											disabled: loading,
											clearable: true,
										}"
									/>
								</v-col>
								<v-col cols="12" md="3" v-if="filterType == 'rangeMonths'">
									<MonthPickerDialog
										dense
										:label="'Hasta'"
										v-model="monthTo"
										date-text-format="MM/YY"
										:textfieldAttrs="{
											outlined: true,
											dense: true,
											hideDetails: true,
											disabled: loading,
											clearable: true,
										}"
									/>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</div>
		<!-- loading -->
		<div class="py-6" v-if="loading">
			<v-progress-linear indeterminate color="primary"></v-progress-linear>
		</div>
		<!-- no bio data -->
		<div class="py-3" v-else-if="noResults">
			<v-row>
				<v-col cols="12">
					<v-card>
						<v-card-title class="grey--text"> No se encontraron resultados </v-card-title>
					</v-card>
				</v-col>
			</v-row>
		</div>
		<!-- balances -->
		<div class="py-3" v-else>
			<!-- monthly totals -->
			<v-row>
				<v-col cols="12">
					<v-card v-for="x of balances.monthlyTotals" :key="`${x.year}${x.month}`">
						<v-card-title> {{ x.monthName }} {{ x.year }} </v-card-title>
						<v-card-text>
							<v-row>
								<v-col cols="4">
									<div><b>Total PVP</b></div>
									<div><PriceText :amount="x.itemsPVP" class="font-4" /></div>
								</v-col>
								<v-col cols="4">
									<div><b>Total BioExperta</b></div>
									<div><PriceText :amount="x.itemsTotal" class="font-4" /></div>
								</v-col>
								<v-col cols="4">
									<div><b>Resultado</b></div>
									<div class="d-flex align-center">
										<b class="mr-1">+</b> <PriceText :amount="x.winnings" class="font-4" />
									</div>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			<!-- ticket average -->
			<v-row>
				<v-col cols="12" md="6">
					<v-card>
						<v-card-title> Ticket Promedio </v-card-title>
						<v-card-text>
							<PriceText class="font-4" :amount="balances.ticketAverage" />
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			<!-- orders totals -->
			<v-row id="orders-totals">
				<v-col cols="12">
					<v-card>
						<v-card-title> Resultados por Orden </v-card-title>
						<v-card-text>
							<v-simple-table dense>
								<thead>
									<tr>
										<th>Fecha</th>
										<th class="w100">Orden</th>
										<th>Total PVP</th>
										<th>Total BioExperta</th>
										<th>Resultado</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="x of ordersTotalsInPage" :key="x.id">
										<td>{{ x.paidAt | date }}</td>
										<td class="py-2">
											<div>
												<a
													target="_blank"
													class="primary--text"
													:href="
														$router.resolve({
															name: 'user.order-detail',
															params: { id: x.id },
														}).href
													"
													><b>Orden #{{ x.code }}</b></a
												>
											</div>
											<div class="grey--text">{{ x.itemsCount }} producto/s</div>
										</td>
										<td><PriceText :amount="x.itemsPVP" /></td>
										<td><PriceText :amount="x.itemsTotal" /></td>
										<td>
											<div class="d-flex align-center">
												<b class="mr-1">+</b> <PriceText :amount="x.winnings" />
											</div>
										</td>
									</tr>
								</tbody>
							</v-simple-table>
						</v-card-text>
						<v-card-actions class="justify-end" v-if="ordersTotalsLastPage > 1">
							<v-pagination
								v-model="ordersPage"
								:total-visible="16"
								:length="ordersTotalsLastPage"
								circle
							></v-pagination>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
			<!-- products totals -->
			<v-row id="products-totals">
				<v-col cols="12">
					<v-card>
						<v-card-title> Resultados por Producto </v-card-title>
						<v-card-text>
							<v-simple-table dense>
								<thead>
									<tr>
										<th></th>
										<th class="w100">Producto</th>
										<th>Cantidad</th>
										<th>Total PVP</th>
										<th>Total BioExperta</th>
										<th>Resultado</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="x of productsTotalsInPage" :key="x.sku">
										<td class="py-2">
											<Media
												:src="x.image"
												width="50px"
												height="50px"
												aspect-ratio="1"
												style="border: 1px solid #ccc; border-radius: 4px"
											/>
										</td>
										<td class="py-2">
											<div class="grey--text nowrap">SKU {{ x.sku }}</div>
											<div>{{ x.name }}</div>
										</td>
										<td class="nowrap">{{ x.itemsCount }} unid.</td>
										<td><PriceText :amount="x.itemsPVP" /></td>
										<td><PriceText :amount="x.itemsTotal" /></td>
										<td>
											<div class="d-flex align-center">
												<b class="mr-1">+</b> <PriceText :amount="x.winnings" />
											</div>
										</td>
									</tr>
								</tbody>
							</v-simple-table>
						</v-card-text>
						<v-card-actions class="justify-end" v-if="productsTotalsLastPage > 1">
							<v-pagination
								v-model="productsPage"
								:total-visible="16"
								:length="productsTotalsLastPage"
								circle
							></v-pagination>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
			<!-- categories totals -->
			<v-row id="categories-totals">
				<v-col cols="12">
					<v-card>
						<v-card-title> Resultados por Categoría </v-card-title>
						<v-card-text>
							<v-simple-table dense>
								<thead>
									<tr>
										<th class="w100">Categoría</th>
										<th>Cantidad</th>
										<th>Total PVP</th>
										<th>Total BioExperta</th>
										<th>Resultado</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="x of categoriesTotalsInPage" :key="x.id">
										<td class="py-2">
											{{ x.categoryFamily }}
										</td>
										<td class="nowrap">{{ x.itemsCount }} unid.</td>
										<td><PriceText :amount="x.itemsPVP" /></td>
										<td><PriceText :amount="x.itemsTotal" /></td>
										<td>
											<div class="d-flex align-center">
												<b class="mr-1">+</b> <PriceText :amount="x.winnings" />
											</div>
										</td>
									</tr>
								</tbody>
							</v-simple-table>
						</v-card-text>
						<v-card-actions class="justify-end" v-if="categoriesTotalsLastPage > 1">
							<v-pagination
								v-model="categoriesPage"
								:total-visible="16"
								:length="categoriesTotalsLastPage"
								circle
							></v-pagination>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</div>
	</UserpanelLayout>
</template>

<style lang="scss" scoped>
th {
	white-space: nowrap;
}
</style>

