<script>
import DatePickerDialog from '@/vuetify-common/comp_DatePickerDialog'
export default {
	extends: DatePickerDialog,
	props: {
		placeholder: {
			type: String,
			default: 'AAAA-MM',
		},
		dateTextFormat: {
			type: String,
			default: 'YYYY-MM',
		},
		pickerProps: {
			type: Object,
			default: () => ({
				type: 'month',
			}),
		},
	},
}
</script>

